:root {
  --color-pink: #ec5990;
  --color-black: #000;
  --color-background: #081229;
  --color-blue: #1a325d;
  --color-grey: #333;
  --color-light-grey: #ccc;
  --color-brown: #8A1C25;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-background);
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 1000%;
}

li{
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #fff;
}

.image{
  width: 100%;
}

.line + .line {
  border-top: solid 1px var(--color-light-grey); 
}